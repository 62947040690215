@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300&display=swap');

body, html {
    height: 100%;
    margin: 0;
    background-color: rgba(240, 239, 235, 0.5);
    overflow-y: hidden;
    font-family: 'Noto Sans', sans-serif; 
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4d4d479;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00000098;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

button {
  font-family: 'Noto Sans', sans-serif; 
}

.artwork-catalog-card-overlay {
  background-color: rgba(0,0,0,0.5);
  width: 200px;
  height: 200px;
  position: relative;
}

.artwork-catalog-card-overlay p {
  position: absolute;
  color: white;
  font-size: 16px;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: fit-content;
}

.aw-appreciation-container {
  display: flex;
}

.aw-appreciation-questions{
  max-width: 350px;
  padding: 20px 30px;
  overflow-y: auto;
  max-height: 64vh;
  overflow-x: hidden;
  font-size: 16px; 
  line-height: 1.8em; 
  margin: auto;
  position: absolute;
  right: 80px;
  top: 70px;
  text-align: left;
  background-color: rgba(255,255,255,0.8);
  z-index: 1;
}

.aw-explanation {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
  padding: 20px 50px;
  overflow-y: auto;
  max-height: 64vh;
  overflow-x: hidden;
}

.aw-explanation p{
  font-size: 20px; 
  line-height: 2em; 
  margin: auto;
  margin-bottom: 20px;
  text-align: left;
}
  
.aw-explanation span {
  font-size: 14px; 
  display: block;
  margin-bottom: 20px;
  line-height: 2em; 
}

.blurred-text {
  color: transparent;
  text-shadow: 0 0 8px #000;
}

.button-general {
  width: 350px;
  text-align: center;
  font-size: 32px;
  padding: 20px 0px;
  display: block;
  margin: 35px auto;
  border: 3px solid #344f15;
  color: #344f15;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

.button-variation {
  border: 3px solid #344f15;
  color: white;
  background-color: #344f15;
}

.caption-container {
  line-height: 0.5em;
  font-size: 16px;
  text-align: center;
  font-weight: 100;
  margin-top: 20px;
}

.catalog-inputs {
  padding: 20px 20px 30px 20px;
  width: 400px;
}

.catalog-inputs > div {
  margin: 0px 30px;
  text-align: left;
  margin-bottom: 20px;
}

.catalog-inputs div input,
.catalog-inputs div select  {
  font-size: 20px;
  width: 100%;
  min-width: 300px;
  max-width: 300px;
}

.catalog-inputs label {
  margin-right: 10px;
  font-size: 22px;;
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
}

.container {
  margin-top: 90px;
  padding: 0px 100px;
}

.continue-course-button {
  height: 20%;
  bottom: 40px;
  margin: auto;
  right: 0;
  left: 0;
}

.continue-course-button button {
  background-color: #344f15;
  color: white;
  outline: none;
  border: none;
  padding: 7px 12px;
  font-size: 36px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.course-div {
  height: 195px;
  width: 300px;
  text-align: center;
  padding-top: 20px;
  border: 1px solid black;
  color: white;
  position: relative;
  margin: 10px 10px;
}

.course-div h4 {
  margin-bottom: 0px;
}

.course-div span {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 15px;
}

.course-list {
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 100px;
  justify-content: center;
  overflow-y: scroll;
  max-height: 80vh;
}

.course-page,
.artwork-page {
  text-align: center;
  margin-top: 60px;
  padding-top: 20px;;
  font-family: 'Noto Sans', sans-serif; 
  font-size: 24px;
  position: relative;
  height: calc(100vh - 120px);
}

.course-page > h1,
.artwork-page > h1 {
  height: 13%;
}

.course-section p {
  font-size: 22px;
  cursor: pointer;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}

.enroll-continue-banner {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 15px;
  padding: 7px 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.explanation-page-container {
  display:flex; 
  padding: 10px 20px;
}

.extra-images-container {
  flex: 1;
}

.extra-img {
  height: 100px;
  width: 100px;
  border: 1px solid black;
  margin: 0px 0px 10px 10px;
  position: relative;
  cursor: pointer;
}

.extra-img img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.flashcard {
  position: relative;
  margin: auto;
  height: 600px;
  width: 800px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(0,0,0,0.8);
  padding: 20px;
  text-align: center;
  transition: 1s;
  display: flex;
  padding-bottom: 35px;
}

.flashcard > div {
  margin:auto;
  height: fit-content;
  height: fit-content;
  display: block;
  font-size: 18px;
}

.flashcard-back {
  display: none;
  flex:1;
}

.flashcard-button {
  position: absolute;
  bottom: 10px;
  margin: auto;
  right: 0;
  left: 0;
  width: fit-content;
  font-size: 32px;
  padding: 5px 10px;
  display: block;
  border: 3px solid #344f15;
  color: white;
  background-color: #344f15;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.flashcard-container{
  margin-top: 40px;
} 

.flashcard-front {
  flex:2;
}

.flashcard-front img {
  max-width: 100%;
  max-height: 520px;
}

.form-container {
  margin-top: 210px;
}

.general-menu-container {
  width: 100%;
  position: absolute;
  top: 0;
  height: 60px;
  /*background-color: #005467;*/
  border-bottom: 1px solid rgba(0,0,0,0.2);
  font-family: 'Noto Sans', sans-serif; 
  z-index: 2;
}

.general-menu-container > div h3 {
  display: inline-block;
  margin: 8px;
  vertical-align: middle;
  font-size: 24px;
}

.general-menu-container a {
  color: inherit;
  text-decoration: none;
}

.general-menu-container h2{
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  color: black;
  font-size: 32px;
  /*text-shadow: 0 0 2px black, 0 0 2px black;*/
}

.general-menu-container .user-area {
  position: absolute;
  right: 20px;
}

.general-menu-mobile {
  display: none;
}

.image-template {
  height: 200px;
  width: 200px;
  padding: 10px;
  cursor: pointer;
}

.img-magnifier-container {
  height: 55vh; 
  margin: auto;
  position:relative;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
}

.img-magnifier-glass {
  position: absolute;
  border: 2px solid #000;
  cursor: none;
  border-radius: 50%;
  /*Set the size of the magnifier glass:*/
  width: 250px;
  height: 250px;
}

.info-icon {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.isss_iframe {
  border:0; 
  width: 100%; 
  height: 55vh;
  flex: 5;
}

.list {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center;
  padding: 0px 20px 30px 20px;
  margin: 10px 0px;
  overflow-y: auto;
  max-height: 78vh;
}

.magnifying-glass {
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-size: 40px;
}

.magnifying-glass-container {
  flex: 1;
  position: relative;
}

.main-img-container {
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px; 
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  padding: 20px;
  display: block;
}

.main-img {
  height: 55vh;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 5;
} 

.main-img img {
  height: 55vh; 
  margin: auto;
  display: block;
  border: 1px solid rgba(0,0,0,0.6);
}

.modal {
  position: fixed;
  inset: 0;
  padding: 20px 40px;
  background-color: white;
  width: 500px;
  height: 300px;
  margin: auto;
  overflow-y: auto;
  border-radius: 10px;
}

.modal h4 {
  text-align: center;
  font-size: 22px;
  margin: 12px 0px;
}

.modal p {
 line-height: 1.7em;
}

.modal-bg {
  position: fixed;
  inset: 0;
  top: 60px;
  background-color: gray;
  opacity: 0.8;
}

.next-class-span {
  font-size: 12px;
  margin-left: 10px;
  background: #344f15;
  color: white;
  padding: 0px 3px;
  border-radius: 5px;
}

.next-flashcard-button {
  position: absolute;
  right: 50px;
  margin: auto;
  top: 0;
  bottom: 0;
  height: fit-content;
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.page-title {
  text-align: center;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 36px;
}

.period-span {
  margin: 0px 6px;
}

.sections-container {
  overflow-y: scroll;
  height: 62%;
  max-width: 900px;
  margin: auto;
}

.show-questions-button {
  position: absolute;
  right: 80px;
  top: 20px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.6);
  border-radius: 5px;
}

.sign-up-page > div {
  text-align: center;
}

.sign-up-page > div h1 {
  margin-top: 170px;
  font-size: 62px;
  font-weight: bold;
}

.sign-up-page > div h2 {
  margin-bottom: 70px;
}

.skip-timer-modal {
  height: 130px;
}

.skip-timer-modal button {
  float: right;
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.6);
  border-radius: 5px;
}

.tag-div {
  font-size: 16px;
  border-radius: 10px;
  padding: 2px 5px;
  margin: 0px 10px 10px 0px;
  width: fit-content;
  cursor: pointer;
}

.tag-div.selected { 
  background-color: #017312;
  color: white;
}

.tag-div.unselected { 
  background-color: rgba(100,100,100,0.1);
  color: rgba(0,0,0,0.5);
}

.template {
  border: 1px solid rgba(20,20,20,0.4);
  margin: 1px;
  position: relative;
}

.text-input-container {
  width: 350px;
  margin: auto;
  text-align: left;
}

.text-input {
  margin-bottom: 20px;
}

.text-input label {
  display: block;
  font-size: 22px;
}

.text-input input {
  width: 100%;
  font-size: 24px;
  padding: 5px 0px;
}

.timer-button-container {
  position: absolute;
  right: 80px;
  bottom: 20px;
  height: fit-content;
  margin: auto 0px;
  font-size: 55px;
}

.timer-button-container button {
  outline: none;
  cursor: pointer;
  font-size: 16px;
  background-color: white;
  border: 1px solid rgba(0,0,0,0.6);
  border-radius: 5px;
}

.timer-container {
  position: absolute;
  right: 80px;
  bottom: 50px;
  height: fit-content;
  margin: auto 0px;
  font-size: 55px;
}

.toggled-menu-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  cursor: pointer;
}

.visibleMenuContainer {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0,0,0);
  top: 0;
  padding-top: 230px;
  z-index: 1;
}

.visibleMenuContainer h2 {
  text-align: center;
  color: white;
  font-size: 35px;
  padding: 10px;
  cursor: pointer;
}

.youtube_iframe {
  width: 100%; 
  margin-top: 10px;
  height: 50vh;
}

@media (max-width: 600px) {

  .aw-appreciation-container {
    display: block;
  }

  .aw-appreciation-questions {
    width: 80vw;
    max-height: 75vh;
    right: 0px;
    top: 70px;
    background-color: rgba(255,255,255,1);
  }

  .aw-explanation {
    width: fit-content;
    max-width: fit-content;
    min-width: fit-content;
    padding: 0px;
    overflow-y: auto;
    max-height: fit-content;
  }

  .button-general {
    width: 100%;
    font-size: 28px;
    padding: 10px 0px;
  }

  .caption-container {
    line-height: 1.2em;
  }

  .container {
    padding: 0px 20px;
  }

  .continue-course-button {
    bottom: 0px;
  }

  .continue-course-button button {
    font-size: 25px;
  }

  .course-list { 
    padding: 0px 10px;
    margin-top: 20px;
  }

  .course-page,
  .artwork-page {
    margin-top: 0px;
    height: calc(100vh - 100px);
  }

  .artwork-page {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .course-page > h1,
  .artwork-page > h1{
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .course-section p {
    font-size: 18px;
  }

  .explanation-page-container {
    display:block; 
  }

  .extra-images-container {
    display: flex;
    margin-top: 10px;
  }

  .flashcard {
    height: auto;
    width: auto;
    display: block;
    padding-bottom: 20px;
  }

  .flashcard-back p,
  .flashcard-back h3 {
    font-size: 16px;
    margin: 3px 0px;
  }

  .flashcard-button {
    position: static;
  }

  .flashcard-front img {
    max-height: 250px;
  }

  .flashcard-container{
    margin-top: 10px;
  } 

  .form-container {
    margin-top: 160px;
  }

  .general-menu-container {
    width: 100%;
    text-align: center;
    height: 60px;
    position: static;
    border: none;
    display: flex;
  }

  .general-menu-container  h2 {
    position: static;
    margin-top: 20px;
    font-size: 26px;
  }

  .general-menu-container .user-area { 
    position: absolute;
    background-color: rgba(240, 239, 235, 0.9);
    z-index: 3;
    width: 100vw;
    right: 0;
    left: 0;
    top: 80px;
  }

  .general-menu-mobile {
    display: block;
  }

  .magnifying-glass-container {
    display: none;
  }

  .main-img-container {
    width: 95%;
    min-width: 95%;
    max-width: 95%;
    margin-top: 0px;
  }

  .modal {
    width: 220px;
  }

  .next-flashcard-button {
    position: relative;
    text-align: right;
    right: 10px;
  }

  .page-title {
    font-size: 28px;
  }

  .sign-up-page > div h1 {
    font-size: 52px;
  }

  .sections-container { 
    padding: 15px 15px 0px 15px;
  }

  .show-questions-button {
    right: 20px;
  }

  .sign-up-page > div h2 {
   margin-bottom: 50px;
   font-size: 20px;
  }

  .skip-timer-modal {
    height: 180px;
  }

  .text-input-container {
    width: 100%;
  }

  .timer-container {
    position: relative;
    text-align: right;
    font-size: 32px;
    right: 10px;
    bottom: 0px;
  }

  .timer-button-container {
    position: static;
    text-align: right;
  }

  .toggled-menu-container {
    position: relative;
    margin-left: 20px;
    text-align: left;
    top: 0;
    left: 0;
    width: fit-content
  }

  .visibleMenuContainer {
    padding-top: 140px;
  }

  .visibleMenuContainer h2 {
    font-size: 30px;
  }

}